import React, { useEffect, useState } from 'react';
import { Header, Grid } from 'semantic-ui-react';

import CheckoutAddress from '../CheckoutAddress';
import FormAddress from '../CheckoutAddress/FormAddress';
import CheckoutShipping from '../CheckoutShipping';
import CheckoutPayment from '../CheckoutPayment';
import CheckoutSummary from '../CheckoutSummary';

function StepOne({
  cart,
  shipping,
  setShipping,
  address,
  setAddress,
  hiddenButtons,
}) {
  const [changeAddress, setChangeAddress] = useState(false);

  useEffect(() => {
    hiddenButtons(changeAddress);
  }, [changeAddress, hiddenButtons]);

  const onUpdateAddress = address => {
    setAddress(address);
    setChangeAddress(prevState => !prevState);
  };

  return (
    <Grid columns={2} doubling>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3">Detalhes do pedido</Header>

          {!changeAddress ? (
            <>
              <CheckoutAddress resource={address} onUpdate={onUpdateAddress} />
              <CheckoutShipping shipping={shipping} setShipping={setShipping} />
            </>
          ) : (
            <FormAddress resource={address} onUpdateAddress={onUpdateAddress} />
          )}
        </Grid.Column>
        <Grid.Column>
          <CheckoutSummary cart={cart} shipping={shipping} />
          <CheckoutPayment paymentType={'money'} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default StepOne;
