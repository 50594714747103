import React, { useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import AddressForm from '../../../../components/Account/Address/AddressForm';
import Account from '../../../../services/account';

function FormAddress({ resource, onUpdateAddress }) {
  const [apiError, setApiError] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = values => {
    setLoading(true);
    const { id } = resource;
    if (id === undefined) {
      Account.addressesCreate({ ...values, is_default: true })
        .then(() => {
          onUpdateAddress({
            ...values,
            id,
          });
          setApiError([]);
        })
        .catch(e => {
          setApiError(e.response.data.errors);
        })
        .finally(() => setLoading(false));
    } else {
      Account.addressesUpdate(id, { ...values, is_default: true })
        .then(() => {
          onUpdateAddress({
            ...values,
            id,
          });
          setApiError([]);
        })
        .catch(e => {
          setApiError(e.response.data.errors);
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <Segment.Group>
      <Segment>
        <Header as="h4">Endereço de Entrega</Header>
        <AddressForm
          apiError={apiError}
          onSubmit={onSubmit}
          loading={loading}
          resource={resource}
          hiddenDefault={true}
        />
      </Segment>
    </Segment.Group>
  );
}

export default FormAddress;
