import React from 'react';
import { Step, Icon } from 'semantic-ui-react';

function CheckoutSteps({ actived, setActived }) {
  const changeStep = active => {
    setActived(active);
  };

  return (
    <Step.Group fluid size={'small'}>
      <Step
        active={actived === 'step-one'}
        completed={actived === 'step-two'}
        onClick={() => changeStep('step-one')}
      >
        <Icon name="plane" />
        <Step.Content>
          <Step.Title>Detalhes do pedido</Step.Title>
          <Step.Description>
            Escolha sua opção de entrega e pagamento
          </Step.Description>
        </Step.Content>
      </Step>
      <Step
        active={actived === 'step-two'}
        disabled={actived !== 'step-two'}
        onClick={() => changeStep('step-two')}
      >
        <Icon name="info circle" />
        <Step.Content>
          <Step.Title>Confirmar Pedido</Step.Title>
          <Step.Description>
            Itens selecionados endereço de entrega e pagamento
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}

export default CheckoutSteps;
