import React from 'react';
import { Button, Segment } from 'semantic-ui-react';

function ButtonsSteps({ actived, setActived, onSubmit, isHidden }) {
  const onClickNext = () => {
    if (actived === 'step-one') {
      setActived('step-two');
    }

    if (actived === 'step-two') {
      onSubmit();
    }
  };

  const onClickPrevious = () => {
    if (actived === 'step-two') {
      setActived('step-one');
    }
  };

  const buttonColor = actived === 'step-two' ? 'orange' : 'vk';
  const buttonText = () => {
    let text = 'Confirmar pedido';

    if (actived === 'step-two') {
      text = 'Finalizar';
    }

    return text;
  };

  if (isHidden) {
    return <></>;
  }

  return (
    <Segment textAlign={'right'}>
      <Button.Group size="large">
        <Button onClick={onClickPrevious} disabled={actived === 'step-one'}>
          Voltar
        </Button>
        <Button.Or text="<>" />
        <Button onClick={onClickNext} color={buttonColor}>
          {buttonText()}
        </Button>
      </Button.Group>
    </Segment>
  );
}

export default ButtonsSteps;
