import React from 'react';
import { Header, Table, Segment, List } from 'semantic-ui-react';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _groupBy from 'lodash/groupBy';

import { formatToMoney } from '../../../../utils/money';

function CheckoutSummary({ cart, shipping }) {
  const subTotal = formatToMoney(_get(cart, 'subTotal', 0));
  const total = formatToMoney(_get(cart, 'total', 0));
  const totalQuantity = _get(cart, 'getTotalQuantity', 0);
  const packageList = _groupBy(cart.cart, 'attributes.package.name');

  const shippingName = _get(shipping, 'name', '--');
  const shippingValue = formatToMoney(_get(shipping, 'value', 0));

  return (
    <>
      <Header as="h3">Resumo do pedido</Header>
      <Table columns={3} celled attached={false}>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={2}>
              <b>Subtotal</b>
            </Table.Cell>
            <Table.Cell width={1}>R$ {subTotal}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={2}>
              <b>Frete ({shippingName})</b>
            </Table.Cell>
            <Table.Cell width={1}>R$ {shippingValue}</Table.Cell>
          </Table.Row>
          <Table.Row warning>
            <Table.Cell width={2}>
              <b>Total</b>
            </Table.Cell>
            <Table.Cell width={1}>R$ {total}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Segment>
        <p>Número de peças e pacotes selecionados:</p>
        <List>
          <List.Item>
            <b>Total de pacotes:</b> {_size(packageList)} pacote(s)
          </List.Item>
          <List.Item>
            <b>Total de items:</b> {totalQuantity} peça(s)
          </List.Item>
        </List>
      </Segment>
    </>
  );
}

export default CheckoutSummary;
