import React from 'react';
import { Message } from 'semantic-ui-react';
import _first from 'lodash/first';

const handleErrors = errors => {
  if (!Array.isArray(errors) && !errors.length > 0) {
    let content = Object.keys(errors).map(e => {
      let error = errors[e];

      return _first(error);
    });

    return (
      <Message error header="Ops!!! Formulário contém erros." list={content} />
    );
  }

  return errors.map(e => (
    <Message error header={e.title} content={e.detail} key={e.status} />
  ));
};

export default handleErrors;
