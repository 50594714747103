import React from 'react';
import { Segment, Button, Header } from 'semantic-ui-react';

function CheckoutAddress({ resource, onUpdate }) {
  let {
    id,
    contact_name,
    contact_position,
    zip_code,
    street,
    number,
    city_block,
    city,
    state,
    additional_info,
  } = resource;

  const onChangeAddress = address => {
    console.log(id, 'id');
    onUpdate(address);
  };

  return (
    <Segment.Group>
      <Segment>
        <Header as="h4">Endereço de Entrega</Header>
        {contact_position && <Header as={'h4'}>{contact_position}</Header>}
        {contact_name}
        <br />
        {zip_code}
        <br />
        {street}, {number}
        {additional_info ? ` - ${additional_info}` : null}
        <br />
        {city_block}
        <br />
        {city}/{state}
        <br />
      </Segment>
      <Segment>
        <Button color="orange" fluid onClick={() => onChangeAddress(resource)}>
          Alterar Endereço
        </Button>
      </Segment>
    </Segment.Group>
  );
}

export default CheckoutAddress;
