import React, { useState } from 'react';
import { Divider, Header, Segment, Responsive } from 'semantic-ui-react';
import CheckoutSteps from './components/CheckoutSteps';
import StepOne from './components/StepOne';
import ButtonsSteps from './components/ButtonsSteps';

const Steps = ({
  cart,
  shipping,
  setShipping,
  step,
  address,
  setAddress,
  setHiddenButtons,
}) =>
  ({
    'step-one': (
      <StepOne
        cart={cart}
        address={address}
        setAddress={setAddress}
        hiddenButtons={setHiddenButtons}
        shipping={shipping}
        setShipping={setShipping}
      />
    ),
    'step-two': 'confirmar pedido',
  }[step]);

function CheckoutModule({ cart, shipping, setShipping, address, setAddress }) {
  const [actived, setActived] = useState('step-one');
  const [hiddenButtons, setHiddenButtons] = useState(false);

  return (
    <>
      <Header as="h2">Finalizar Pedido</Header>
      <Divider />
      <Responsive minWidth={768}>
        <CheckoutSteps actived={actived} setActived={setActived} />
      </Responsive>

      <Segment.Group>
        <Segment>
          <Steps
            cart={cart}
            shipping={shipping}
            setShipping={setShipping}
            step={actived}
            address={address}
            setAddress={setAddress}
            setHiddenButtons={setHiddenButtons}
          />
        </Segment>
        <ButtonsSteps
          actived={actived}
          setActived={setActived}
          isHidden={hiddenButtons}
        />
      </Segment.Group>
    </>
  );
}

export default CheckoutModule;
