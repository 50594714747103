import React, { useState } from 'react';
import { Segment, Form, Radio, Header } from 'semantic-ui-react';
import Checkout from '../../../../services/checkout';

const CheckoutPayment = ({ paymentType }) => {
  const [payment, setPayment] = useState(paymentType);
  const [loading, setLoading] = useState(false);

  const onChange = payment => {
    setPayment(payment);
    setLoading(true);
    let paymentMethod = '';

    if (payment === 'money') {
      paymentMethod = 'Dinheiro';
    } else {
      paymentMethod = 'Cartão';
    }

    Checkout.payment({
      payment_code: payment,
      payment_method: paymentMethod,
    }).then(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <Segment loading={loading}>
        <Header as="h4">Pagamento</Header>
        <Form.Field>
          <Radio
            label="Dinheiro"
            name="payment"
            value="money"
            checked={payment === 'money'}
            onChange={() => onChange('money')}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="PayPal (Cartão de crédito)"
            name="payment"
            value="paypal"
            checked={payment === 'paypal'}
            onChange={() => onChange('paypal')}
          />
        </Form.Field>
      </Segment>
    </>
  );
};

export default CheckoutPayment;
