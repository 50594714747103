import React, { useState } from 'react';
import { Button, Form, Grid, Responsive } from 'semantic-ui-react';
import useForm from 'react-hook-form';

import handleErrors from '../../HandleErrors';
import CityStateMobile from './FieldsAddressResponsive/CityStateMobile';
import CityStateDesktop from './FieldsAddressResponsive/CityStateDesktop';

function AddressForm({
  resource = {
    id: 0,
    contact_name: '',
    contact_position: null,
    zip_code: '',
    street: '',
    number: '',
    city_block: '',
    city: '',
    state: '',
    additional_info: null,
    country: 'Brasil',
    is_default: false,
  },
  apiError,
  onSubmit,
  loading,
  hiddenDefault = false,
}) {
  const [state, setState] = useState(resource.state);
  const [isDefault, setIsDefault] = useState(resource.is_default);

  const toggle = (prev, data) => {
    setIsDefault(data.checked);
  };

  const onSubmitInternal = async data => {
    data = { ...data, country: 'Brasil', state: state, is_default: isDefault };

    await onSubmit(data);
  };

  const { register, handleSubmit, errors } = useForm({
    defaultValues: resource,
  });

  return (
    <>
      {apiError.length !== 0 ? handleErrors(apiError) : null}

      <Form
        onSubmit={handleSubmit(onSubmitInternal)}
        loading={loading}
        error={errors.length !== 0 || Object.entries(errors).length !== 0}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Form.Field>
                <label>Nome de Contato</label>
                <input type="text" name="contact_name" ref={register} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.Field>
                <label>Tipo de Contato</label>
                <input type="text" name="contact_position" ref={register} />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4} mobile={16}>
              <Form.Field>
                <label>CEP</label>
                <input type="text" name="zip_code" ref={register} width={4} />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10} mobile={10}>
              <Form.Field>
                <label>Endereço</label>
                <input type="text" name="street" ref={register} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={4} mobile={6}>
              <Form.Field>
                <label>Número</label>
                <input type="text" name="number" ref={register} />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4} mobile={8}>
              <Form.Field>
                <label>Bairro</label>
                <input type="text" name="city_block" ref={register} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={8} mobile={8}>
              <Form.Field>
                <label>Complemento</label>
                <input type="text" name="additional_info" ref={register} />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Responsive
            as={React.Fragment}
            maxWidth={Responsive.onlyMobile.maxWidth}
            minWidth={Responsive.onlyMobile.minWidth}
          >
            <CityStateMobile
              register={register}
              state={state}
              toggle={toggle}
              isDefault={isDefault}
              setState={setState}
              hiddenDefault={hiddenDefault}
            />
          </Responsive>
          <Responsive
            as={React.Fragment}
            minWidth={Responsive.onlyComputer.minWidth}
            maxWidth={Responsive.onlyComputer.maxWidth}
          >
            <CityStateDesktop
              register={register}
              state={state}
              toggle={toggle}
              isDefault={isDefault}
              setState={setState}
              hiddenDefault={hiddenDefault}
            />
          </Responsive>
          <Grid.Row>
            <Grid.Column width={16}>
              <Button type="submit" color="orange">
                Salvar
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
}

export const stateOptions = [
  { key: 'AC', value: 'AC', text: 'Acre' },
  { key: 'AL', value: 'AL', text: 'Alagoas' },
  { key: 'AP', value: 'AP', text: 'Amapá' },
  { key: 'AM', value: 'AM', text: 'Amazonas' },
  { key: 'BA', value: 'BA', text: 'Bahia' },
  { key: 'CE', value: 'CE', text: 'Ceará' },
  { key: 'DF', value: 'DF', text: 'Distrito Federal' },
  { key: 'ES', value: 'ES', text: 'Espírito Santo' },
  { key: 'GO', value: 'GO', text: 'Goías' },
  { key: 'MA', value: 'MA', text: 'Maranhão' },
  { key: 'MT', value: 'MT', text: 'Mato Grosso' },
  { key: 'MS', value: 'MS', text: 'Mato Grosso do Sul' },
  { key: 'MG', value: 'MG', text: 'Minas Gerais' },
  { key: 'PA', value: 'PA', text: 'Pará' },
  { key: 'PB', value: 'PB', text: 'Paraíba' },
  { key: 'PR', value: 'PR', text: 'Paraná' },
  { key: 'PE', value: 'PE', text: 'Pernambuco' },
  { key: 'PI', value: 'PI', text: 'Piauí' },
  { key: 'RJ', value: 'RJ', text: 'Rio de Janeiro' },
  { key: 'RN', value: 'RN', text: 'Rio Grande do Norte' },
  { key: 'RS', value: 'RS', text: 'Rio Grande do Sul' },
  { key: 'RO', value: 'RO', text: 'Rondônia' },
  { key: 'RR', value: 'RR', text: 'Roraíma' },
  { key: 'SC', value: 'SC', text: 'Santa Catarina' },
  { key: 'SP', value: 'SP', text: 'São Paulo' },
  { key: 'SE', value: 'SE', text: 'Sergipe' },
  { key: 'TO', value: 'TO', text: 'Tocantins' },
];

export default AddressForm;
