import React from 'react';
import { Checkbox, Form, Grid, GridRow, Select } from 'semantic-ui-react';
import { stateOptions } from '../AddressForm';

const CityStateMobile = ({
  register,
  state,
  toggle,
  isDefault,
  setState,
  hiddenDefault,
}) => (
  <>
    <GridRow>
      <Grid.Column width={16}>
        <Form.Field>
          <label>Cidade</label>
          <input type="text" name="city" ref={register} />
        </Form.Field>
      </Grid.Column>
    </GridRow>
    <GridRow>
      <Grid.Column width={16}>
        <Form.Field>
          <label>Estado</label>
          <Select
            placeholder="Estado"
            name="state"
            onChange={(p, data) => setState(data.value)}
            defaultValue={state}
            options={stateOptions}
          />
        </Form.Field>
      </Grid.Column>
    </GridRow>
    {hiddenDefault || (
      <GridRow>
        <Grid.Column width={16} verticalAlign={'top'}>
          <Form.Field>
            <Checkbox
              name="is_default"
              ref={register}
              onChange={toggle}
              checked={isDefault}
              label="Este é seu endereço principal para entregas?"
            />
          </Form.Field>
        </Grid.Column>
      </GridRow>
    )}
  </>
);

export default CityStateMobile;
