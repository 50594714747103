import React, { useEffect, useContext, useState, useCallback } from 'react';
import _find from 'lodash/find';
import _get from 'lodash/get';

import SEO from '../components/SEO';
import AuthContext from '../components/Context/AuthContext';
import Layout from '../components/Layout';
import Checkout from '../services/checkout';
import { navigate } from '@reach/router';
// import CartContext from '../components/Context/CartContext';
import CheckoutModule from '../module/checkout';
import Account from '../services/account';

const CheckoutPage = ({ location }) => {
  const { token } = useContext(AuthContext);
  const [cart, setCart] = useState({});
  const [shipping, setShipping] = useState({
    name: 'PAC',
    value: 0,
    deadline: 0,
  });
  // const { updateCartCount } = useContext(CartContext);
  // const [comment, setComment] = useState('');
  const [address, setAddress] = useState({});

  const initCheckout = useCallback(async () => {
    const data = await Checkout.init();

    setCart(data);
    const conditions = _get(data, 'conditions', []);
    const auxShipping = _find(conditions, { type: 'shipping' });
    if (auxShipping) {
      setShipping({
        name: auxShipping.name,
        value: auxShipping.value,
        deadline: auxShipping.attributes.deadline,
      });
    }
  }, []);

  const getAddress = useCallback(() => {
    Account.addresses().then(data => {
      const addresses =
        _find(_get(data, 'addresses', []), a => a.is_default === true) || {};
      setAddress(addresses);
    });
  }, []);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }

    initCheckout();
    getAddress();
  }, [token, getAddress, initCheckout]);

  // const checkoutCart = () => {
  //   Checkout.finish(comment).then(() => {
  //     const cartId = localStorage.getItem('mcart');
  //     updateCartCount(0, cartId);
  //     localStorage.removeItem('mcart');
  //     navigate('/');
  //   });
  // };

  return (
    <Layout location={location} hiddenFooter>
      <SEO title="Finalizar Pedido" />

      <CheckoutModule
        shipping={shipping}
        setShipping={setShipping}
        cart={cart}
        address={address}
        setAddress={setAddress}
      />
    </Layout>
  );
};

export default CheckoutPage;
