import http from '../plugins/http';
import { getData } from '../utils/get';

const Checkout = {
  init: () => http.post('/api/checkout').then(getData),
  shipping: ({ value, name, deadline }) =>
    http
      .post('/api/checkout/shipping', { value, name, deadline })
      .then(getData),
  payment: ({ payment_code, payment_method }) =>
    http
      .post('/api/checkout/payment', { payment_code, payment_method })
      .then(getData),
  finish: comment =>
    http.post('/api/checkout/finish', { comment }).then(getData),
};

export default Checkout;
