import http from '../plugins/http';
import { getData } from '../utils/get';

const Account = {
  me: () => http.get('/api/user').then(getData),

  login: ({ email, password }) =>
    http.post('/api/login', { email, password }).then(getData),

  register: ({
    name,
    email,
    phone,
    document,
    password,
    password_confirmation,
  }) =>
    http
      .post('/api/register', {
        name,
        email,
        phone,
        document,
        password,
        password_confirmation,
      })
      .then(getData),

  updatePassword: ({ password, password_confirmation }) =>
    http
      .post('/api/user/password', { password, password_confirmation })
      .then(getData),

  updateAccount: ({ name, document, phone, email }) =>
    http.post('/api/user', { name, document, phone, email }).then(getData),

  addresses: () => http.get('/api/address/?include=addresses').then(getData),

  addressesCreate: data =>
    http.post('/api/address/?include=addresses', data).then(getData),

  addressesUpdate: (id, data) =>
    http.put('/api/address/' + id + '/?include=addresses', data).then(getData),

  addressesDestroy: id => http.delete('/api/address/' + id).then(getData),
};

export default Account;
