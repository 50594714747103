import React, { useState } from 'react';
import { Segment, Form, Radio, Header } from 'semantic-ui-react';
import Checkout from '../../../../services/checkout';

const CheckoutShipping = ({ shipping, setShipping }) => {
  const [loading, setLoading] = useState(false);

  const onChange = value => {
    setLoading(true);
    const payload = {
      name: value === 'pac' ? 'PAC' : 'Sedex',
      value: 21.6,
      deadline: 2,
    };

    Checkout.shipping(payload).then(() => {
      setShipping(payload);
      setLoading(false);
    });
  };

  return (
    <Segment loading={loading}>
      <Header as="h4">Entrega</Header>

      <Form.Field>
        <Radio
          label="Pac"
          name="shipping"
          value="pac"
          checked={shipping.name.toLowerCase() === 'pac'}
          onChange={() => onChange('pac')}
        />
      </Form.Field>
      <Form.Field>
        <Radio
          label="Sedex"
          name="shipping"
          value="sedex"
          checked={shipping.name.toLowerCase() === 'sedex'}
          onChange={() => onChange('sedex')}
        />
      </Form.Field>
    </Segment>
  );
};

export default CheckoutShipping;
